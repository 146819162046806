<template>
    <div class="pro3Com4" :style="{ display: true }" style="display: none">
        <div class="p34Box">
            <!-- 左文 -->
            <div class="p34Left wow animate__animated animate__fadeInLeft">
                <p class="pl1">我们试图</p>
                <p class="pl2">让广告出现在优质的媒体内容里</p>
                <p class="pl3">越来越多优质的传统媒体与自媒体</p>
                <p class="pl3">开通流量主功能</p>
            </div>
            <!-- 右图 -->
            <div class="p34Right wow animate__animated animate__fadeInRight">
                <img src="https://iv.vu818.com/img/gg4_3.png" alt="" class="p34Img" />
                <div>
                    <img src="https://iv.vu818.com/img/gg4_2.png" alt="" class="center" />
                    <div>
                        <div>
                            <!-- <img src="http://iv.vu818.com/img/2-4.png" alt="" /> -->
                        </div>
                    </div>
                    <div>
                        <div>
                            <!-- <img src="http://iv.vu818.com/img/pc333.png" alt="" /> -->
                        </div>
                    </div>
                </div>
                <!-- 旁边的四个小图标 -->
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pro3Com4',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
        this.$nextTick(() => {});
    },
};
</script>

<style lang="less">
.pro3Com4 {
    min-width: 1440px;
    height: 500px;
    margin-top: 50px;
    overflow: hidden;
    // background-color: #f8f8f8;
    .p34Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左文
        .p34Left {
            width: 40%;
            height: 30%;
            float: left;
            margin-top: 150px;
            padding-left: 30px;
            .pl1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-weight: 900;
                font-size: 24px;
            }
            .pl2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .pl3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
        // 右图
        .p34Right {
            width: 50%;
            height: 100%;
            float: right;
            position: relative;
            background: url('https://iv.vu818.com/img/gg4_1.png');
            // 线图
            .p34Img:nth-child(1) {
                position: absolute;
                top: 150px;
                left: 20px;
                z-index: -1;
            }

            & > div:nth-of-type(1) {
                width: 560px;
                height: 560px;
                position: absolute;
                top: -60px;
                left: 21px;
                border-radius: 50%;
                animation: an 30s linear infinite;

                @keyframes an {
                    0% {
                        transform: rotateX(76deg) rotateZ(0deg);
                    }

                    100% {
                        transform: rotateX(76deg) rotateZ(-360deg);
                    }
                }
                // 中心圆图
                .center {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    width: 314px;
                    animation: an3 30s linear infinite;

                    @keyframes an3 {
                        0% {
                            transform: rotateZ(0deg) scaleY(4.2);
                        }

                        100% {
                            transform: rotateZ(360deg) scaleY(4.2);
                        }
                    }
                }
                & > div {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    & > div {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        background: url('https://iv.vu818.com/img/vu618.png') no-repeat;
                    }
                }

                & > div:nth-of-type(1) {
                    // 识图
                    top: 250px;
                    left: -50px;
                    animation: ma 30s linear infinite;
                    @keyframes ma {
                        0% {
                            transform: scale(1);
                            z-index: 1;
                        }
                        50% {
                            transform: scale(1);
                            z-index: -1;
                        }
                        75% {
                            transform: scale(0.3);
                        }
                        99% {
                            z-index: -1;
                        }
                        100% {
                            transform: scale(1);
                            z-index: 1;
                        }
                    }
                    & > div {
                        animation: an21 30s linear infinite;
                        background-position: -649px -1362px;
                        @keyframes an21 {
                            0% {
                                transform: rotateZ(0deg) scaleY(4.1);
                            }
                            100% {
                                transform: rotateZ(360deg) scaleY(4.1);
                            }
                        }
                    }
                }
                & > div:nth-of-type(2) {
                    // 钓鱼图
                    top: 250px;
                    right: -50px;
                    animation: ma2 30s linear infinite;
                    @keyframes ma2 {
                        0% {
                            transform: scale(1);
                            z-index: -1;
                        }
                        25% {
                            transform: scale(0.6);
                            z-index: -1;
                        }
                        50% {
                            transform: scale(1);
                            z-index: -1;
                        }
                        100% {
                            transform: scale(1);
                            z-index: 1;
                        }
                    }
                    & > div {
                        animation: an22 30s linear infinite;
                        background-position: 2px -1446px;
                        @keyframes an22 {
                            0% {
                                transform: rotateZ(0deg) scaleY(4.1);
                            }
                            1% {
                                z-index: -1;
                            }
                            25% {
                                z-index: -1;
                            }
                            100% {
                                transform: rotateZ(360deg) scaleY(4.1);
                            }
                        }
                    }
                }
            }
            & > div:nth-of-type(2),
            & > div:nth-of-type(3),
            & > div:nth-of-type(4),
            & > div:nth-of-type(5) {
                width: 80px;
                height: 80px;
                background: url('https://iv.vu818.com/img/vu618.png') no-repeat;
                position: absolute;
            }
            & > div:nth-of-type(2) {
                background-position: -484px -1362px;
                top: 300px;
                left: 28px;
                animation: an11 30s linear infinite;
                @keyframes an11 {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(0.8);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }
            & > div:nth-of-type(3) {
                background-position: -600px -1445px;
                animation: an12 10s linear infinite;
                @keyframes an12 {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.5);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }
            & > div:nth-of-type(4) {
                background-position: -569px -1362px;
                bottom: 80px;
                right: 102px;
                animation: an13 4s linear infinite;
                @keyframes an13 {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(0.8);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }
            & > div:nth-of-type(5) {
                background-position: -90px -1446px;
                right: 80px;
                animation: an14 8s linear infinite;
                @keyframes an14 {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(0.8);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
</style>
