<template>
    <div class="pro3Com5">
        <div class="p35Box">
            <!-- 左图 -->
            <div class="p35Left">
                <img src="http://iv.vu818.com/img/pc341.png" alt="" class="p35Imgs wow animate__animated animate__fadeInLeft" />
                <img src="http://iv.vu818.com/img/pc342.png" alt="" class="p35Img wow animate__animated animate__fadeInUp" data-wow-delay="0.3s" />
                <img src="http://iv.vu818.com/img/pc343.png" alt="" class="p35Img wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" />
                <img src="http://iv.vu818.com/img/pc344.png" alt="" class="p35Img wow animate__animated animate__fadeInUp" data-wow-delay="0.9s" />
            </div>
            <!-- 右文 -->
            <div class="p35Right wow animate__animated animate__fadeInRight">
                <p class="pr1">我们试图</p>
                <p class="pr2">让广告的投放变得简单</p>
                <p class="pr3">丰富的广告形态，满足日益精细的投放需求</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pro3Com5',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro3Com5 {
    min-width: 1423px;
    height: 500px;
    margin-top: 50px;
    .p35Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左图
        .p35Left {
            width: 50%;
            height: 100%;
            float: left;
            text-align: center;
            position: relative;
            .p35Img:nth-child(2) {
                position: absolute;
                top: 100px;
                left: 20px;
            }
            .p35Img:nth-child(3) {
                position: absolute;
                bottom: 50px;
                right: 20px;
            }
            .p35Img:nth-child(4) {
                position: absolute;
                top: 200px;
                right: 50px;
            }
        }
        // 右文
        .p35Right {
            width: 40%;
            height: 30%;
            float: right;
            margin-top: 150px;
            padding-left: 30px;
            .pr1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-weight: 900;
                font-size: 24px;
            }
            .pr2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .pr3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
    }
}
</style>
