<template>
    <div class="pro3Com6">
        <div class="pro3Com6">
            <div class="p36Box">
                <!-- 左文 -->
                <div class="p36Left wow animate__animated animate__fadeInLeft">
                    <p class="pl1">我们试图</p>
                    <p class="pl2">让广告出现在优质的媒体内容里</p>
                    <p class="pl3">越来越多优质的传统媒体与自媒体</p>
                    <p class="pl3">开通流量主功能</p>
                </div>
                <!-- 右图 -->
                <div class="p36Right wow animate__animated animate__fadeInRight" data-wow-duration="1s">
                    <img src="http://iv.vu818.com/img/pc351.png" alt="" class="p36Imgs" />
                    <div class="p36Img">
                        <img
                            src="http://iv.vu818.com/img/pc352.jpg"
                            data-wow-delay="1s"
                            data-wow-duration="2s"
                            class="wow animate__animated animate__fadeInUp"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pro3Com6',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro3Com6 {
    min-width: 1423px;
    height: 600px;
    margin-top: 50px;
    .p36Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左文
        .p36Left {
            width: 40%;
            height: 30%;
            float: left;
            margin-top: 150px;
            padding-left: 30px;
            .pl1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-weight: 900;
                font-size: 24px;
            }
            .pl2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .pl3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
        // 右图
        .p36Right {
            width: 50%;
            height: 100%;
            float: right;
            position: relative;
            overflow: hidden;
            .p36Imgs {
                padding-left: 100px;
            }
            .p36Img {
                width: 300px;
                height: 400px;
                position: absolute;
                top: 160px;
                left: 150px;
                overflow: hidden;
                & > img {
                    position: absolute;
                    top: -386px;
                    left: 0px;
                    width: 100%;
                }
            }
        }
    }
}
</style>
